@import '../../common/styles/variables.scss';

.navigation {
    background: #000;
    position: fixed;
    padding: 32px;
    z-index: 100;
    right: 0;
    left: 0;
    top: 0;

    .logo-mobile {
        width: 32px;
        height: 32px;
        aspect-ratio: 1;
        z-index: 10;
    }

    .menu-hamburger {
        background: #000;
        z-index: 11;
        position: fixed;
        top: 20px;
        right: 34px;
        width: 32px;
        aspect-ratio: 1;
        overflow: hidden;

        .menu-hamburger-items {
            position: relative;
            width: 100%;
            height: 100%;

            span {
                position: absolute;
                display: block;
                background-color: $primaryTextColor;
                height: 3px;
                width: 100%;

                border-radius: 2.5px;

                transform-origin: center;
                transition: 500ms;

                &:nth-child(1) {
                    top: 3px;
                }

                &:nth-child(2) {
                    top: 12px;
                }

                &:nth-child(3) {
                    top: 21px;
                }
            }
        }
    }

    .navigation-items {
        list-style: none;
        height: 65px;

        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .navigation-item {
            flex-basis: auto;
            cursor: pointer;

            &.logo {
                margin-right: auto;
                margin-left: 8px;

                img {
                    width: 60px;
                    height: 60px;
                }
            }

            &.text {
                padding: 0 2rem;
                font-size: 1.5rem;
                color: $secondaryTextColor;
                font-weight: 200;

                transition: 300ms;

                &:hover {
                    color: $titleTextColor;
                }
            }

            &.social {
                margin: 2rem 0;
                display: flex;
                gap: 7rem;
                font-size: 4rem;
                color: $primaryTextColor;

                svg {
                    width: 4rem;
                }
            }
        }
    }

    &.open {
        .menu-hamburger-items {
            span {
                background: $titleTextColor;

                &:nth-child(1) {
                    transform: rotate(45deg) translate(6px, 6px);
                }

                &:nth-child(2) {
                    transform: rotateY(90deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) translate(7px, -7px);
                }
            }
        }
    }
}
