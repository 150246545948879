@import './variables.scss';

.mobile-show {
    display: none !important;
}

@media screen and (max-width: 1800px) {
    body {
        margin-left: 100px;
        margin-right: 75px;

        .ruler-line.left {
            box-shadow: 250px 0px 0px 0px #121212;
        }

        .ruler-line.right {
            box-shadow: -250px 0px 0px 0px #121212;
        }

        .follower-logo {
            right: 45px;

            .follower-logo-note {
                font-size: 1.3rem;
                width: 250px;
                margin-left: -265px;
            }
        }

        .page {
            .page-text {
                p {
                    font-size: 1.5rem;
                }
            }
        }

        #about-me {
            .page-name {
                &::after {
                    left: -110px;
                }
            }
        }

        #projects {
            margin-left: 0px;

            .page-name {
                margin-left: 0px;

                &::after {
                    margin-left: 220px;
                }
            }

            .projects {
                .project {
                    .project-description {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        #recommendations {
            .page-name {
                margin-left: 0px;

                &::after {
                    margin-left: 220px;
                }
            }

            .recommendation-slider-holder {
                padding: 0;

                .recommendation-slider-arrow {
                    &.left {
                        left: -35px;
                    }

                    &.right {
                        right: -10px;
                    }
                }

                .recommendation-slider-wrapper {
                    .recommendation-slider {
                        .recommendation-slider-item {
                            padding: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1450px) {
    body {
        .navigation {
            padding: 20px 32px;
        }

        .page {
            padding-top: 120px;

            .page-title {
                font-size: 4.5rem;
            }
        }

        .projects {
            .project {
                .project-title {
                    font-size: 2rem;
                }
            }

            .backdrop {
                .project {
                    &.expanded {
                        width: 80vw;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    body {
        .ruler-line.left {
            box-shadow: none;
        }

        .ruler-line.right {
            box-shadow: none;
        }

        .follower-logo {
            right: 45px;

            .follower-logo-note {
                font-size: 1.3rem;
                width: 250px;
                margin-left: -265px;
            }
        }

        .fixed-sidemenu {
            gap: 3rem;

            a {
                font-size: 3rem;
            }
        }

        .navigation {
            .navigation-items {
                .navigation-item {
                    &.text {
                        font-size: 2.2rem;
                    }
                }
            }
        }

        .page {
            padding-right: 40px;

            .page-text {
                p {
                    line-height: normal;
                }

                &.indented {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        #services {
            .service-block-holder {
                grid-template-columns: repeat(2, 1fr);

                .service-block {
                    max-width: 100%;

                    .service-block-image {
                        width: 64px;
                    }

                    .service-block-title {
                        font-size: 2.5rem;
                    }

                    .service-block-text {
                        font-size: 2.0rem;
                    }
                }
            }
        }

        #projects {
            margin-left: 0px;

            .page-name {
                &::after {
                    left: -329px;
                }
            }

            .category-filter {
                .category-filter-item {
                    font-size: 2rem;
                }
            }

            .projects {
                margin-right: 20px;

                .project {
                    padding-right: 30px;
                }
            }
        }

        #recommendations {
            .recommendation-slider-arrow {
                &.left {
                    left: -100px;
                }

                &.right {
                    right: 0;
                }
            }

            .recommendation-slider-holder {
                .recommendation-slider-dots {
                    padding-right: 20px;
                    gap: 2.5rem;

                    .recommendation-slider-dot {
                        border-width: 0.2rem;
                        width: 20px;
                        height: 20px;
                    }
                }

                .recommendation-slider-wrapper {
                    .recommendation-slider {
                        .recommendation-slider-item {
                            padding: 0 70px 0 0px;

                            .recommendation-slider-item-text {
                                font-size: 1.75rem;
                            }

                            .recommendation-slider-item-person {
                                font-size: 1rem;
                                // grid-template-columns: 80px 1fr;
                                margin-left: 30px;

                                .recommendation-slider-item-person-photo-holder {
                                    img {
                                        width: 70px;
                                    }
                                }

                                .recommendation-slider-item-name {
                                    font-size: 1.6rem;
                                }

                                .recommendation-slider-item-title {
                                    font-size: 1.8rem;
                                }

                                .recommendation-slider-item-company {
                                    font-size: 1.6rem;
                                }
                                
                                .recommendation-slider-item-date {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .mobile-show {
        display: inherit !important;
    }

    .mobile-hide {
        display: none !important;
    }

    html {
        min-height: auto;

        body {
            width: 100%;
            padding: 0;
            margin: 0;

            .Application {
                padding: 50px;

                .ruler-line {
                    display: none;
                }

                .follower-logo {
                    display: none;
                }

                .navigation {
                    margin: 0;

                    .navigation-items {
                        max-height: 0;

                        transition: 500ms;
                        overflow: hidden;
                        background: #000;
                        z-index: 1;

                        inset: 0;

                        flex-direction: column;
                        height: auto;
                        align-items: center;
                        justify-content: flex-start;
                        align-content: center;
                        gap: 3rem;

                        .navigation-item {
                            &.logo {
                                text-align: center;
                                width: 100%;
                                margin-top: 3rem;
                                margin-bottom: 2rem;
                            }

                            &.text {
                                font-size: 2.2rem;
                                padding: 1rem 0;
                            }
                        }
                    }

                    &.open {
                        .navigation-items {
                            max-height: 100vh;
                            min-height: 100vh;
                            padding: 6rem 0;
                        }
                    }
                }

                .page {
                    min-height: auto;
                    padding: 120px 0;
                }

                .counters {
                    text-align: center;
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    gap: 5rem;

                    .counter {
                        .counter-value {
                            font-size: 3.5rem;
                        }

                        .counter-name {
                            font-size: 1.4rem;
                        }
                    }
                }

                #home {
                    padding-top: 25%;
                    padding-bottom: 15%;
                }

                #services {
                    .service-block {
                        width: 90%;

                        .service-block-title {
                            font-size: 1.6rem;
                        }

                        .service-block-text {
                            font-size: 1.3rem;
                        }
                    }
                }

                #projects {
                    .category-filter {
                        gap: 1rem;

                        .category-filter-item {
                            text-transform: none;
                            font-size: 1.6rem;
                        }
                    }

                    .projects {
                        .project {
                            flex: 0 0 auto;
                            width: 50%;

                            .project-skills {
                                margin-top: 1rem;

                                .skill {
                                    font-size: 1.1rem;
                                }
                            }

                            .project-description {
                                padding: 0 20px;
                                font-size: 1.4rem;
                            }
                        }

                        .backdrop {
                            .project {
                                &.expanded {
                                    width: 80vw;

                                    .project-description {
                                        padding: 0;
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }
                }

                #recommendations {
                    .recommendation-slider-arrow {
                        display: none;
                    }

                    .recommendation-slider-holder {
                        .recommendation-slider-dots {
                            .recommendation-slider-dot {
                                border-width: 0.2rem;
                                aspect-ratio: 1;
                            }
                        }

                        .recommendation-slider-wrapper {
                            .recommendation-slider {
                                .recommendation-slider-item {
                                    padding: 0px;
                                    padding-right: 10px;

                                    .recommendation-slider-item-text {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 860px) {
    body {
        #recommendations {
            .recommendation-slider-holder {
                .recommendation-slider-wrapper {
                    .recommendation-slider {
                        .recommendation-slider-item {
                            .recommendation-slider-item-text {
                                p {
                                    font-size: 1.5rem;
                                }
                            }
                            .recommendation-slider-item-person {
                                .recommendation-slider-item-title {
                                    font-size: 1.2rem;
                                }

                                .recommendation-slider-item-company {
                                    font-size: 1.2rem;
                                }
                            }
                            
                        }
                    }
                }

                .recommendation-slider-dots {
                    gap: 0.5rem !important;

                    .recommendation-slider-dot {
                        width: 10px;
                        height: 10px;

                        &.active {
                            border-width: 0.05rem !important;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    html {
        body {
            .Application {
                padding: 30px !important;
    
                .navigation {
                    .navigation-items {
                        .navigation-item {
                            &.social {
                                gap: 3rem;
                            }
                        }
                    }
                }
    
                .page {
                    padding: 30px 0;
    
                    .page-name {
                        font-size: 1.4rem;
    
                        &::after {
                            display: none;
                        }
                    }
    
                    .page-title {
                        font-size: 2.6rem;
                    }
    
                    .page-subtitle {
                        font-size: 2.2rem;
                    }
    
                    .page-text {
                        font-size: 1.4rem;
    
                        p {
                            font-size: 1.4rem;
                        }
                    }
                }
    
                #services {
                    .service-block-holder {
                        grid-template-columns: 1fr;
    
                        .service-block {
                            width: 100% !important;
                            max-width: 100% !important;
                        }
                    }
    
                    .counters {
                        display: flex;
                        flex-flow: row wrap;
                        gap: 3rem;
                        padding: 0;
                    }
                }
    
                #projects {
                    .category-filter {
                        flex-wrap: wrap;

                        .category-filter-item {
                            font-size: 1.2rem;
                        }
                    }

                    .projects {
                        margin-right: 0 !important;
    
                        .project {
                            width: 100%;
        
                            .project-skills {
                                .skill {
                                    font-size: 0.9rem;
                                }
                            }

                            .project-description {
                                padding: 0;
                            }
                        }

                        .backdrop {
                            .project {
                                &.expanded {
                                    width: 100%;
                                    box-sizing: border-box;
                                    padding: 0;

                                    .project-title {
                                        font-size: 3.2rem;
                                    }

                                    .project-description {
                                        padding: 0;
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }
                }

                #recommendations {
                    .recommendation-slider-holder {
                        padding: 0;

                        .recommendation-slider-wrapper {
                            .recommendation-slider {
                                .recommendation-slider-item {
                                    .recommendation-slider-item-text {
                                        padding: 0 0 2rem 0;

                                        p {
                                            font-size: 1.2rem;
                                        }
                                    }

                                    .recommendation-slider-item-person {
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }

                       .recommendation-slider-dots {
                            gap: 1rem !important;
                            padding: 0;
                        }
                    }
                }

                .backdrop {
                    padding: 2rem;
                }
            }
        }
    }
}
