@import '../../common/styles/variables.scss';

::-webkit-scrollbar {
    display: none;
}
  
html {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ruler-line {
    background-color: #121212;
    position: fixed;
    width: 1px;
    bottom: 0;
    top: 0;
    z-index: -1;

    &.left {
        left: 70px;
        box-shadow: 250px 0px 0px 0px #121212, 500px 0px 0px 0px #121212;
    }

    &.right {
        right: 63px;
        box-shadow: -250px 0px 0px 0px #121212, -500px 0px 0px 0px #121212;
    }
}

.follower-logo {
    background-image: url('../../common/images/milantex-logo.svg');
    background-size: 100%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 170px;
    right: 294px;
    transition: 1000ms;

    .follower-logo-note {
        background-color: $primaryTextColor;
        color: #000000;
        font-size: 1.1rem;
        border-radius: 10px;
        position: relative;
        padding: 15px 20px;
        width: 210px;
        margin-left: -225px;

        transition: 600ms;
        opacity: 0;
    }

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $primaryTextColor;
        position: relative;
        margin-left: -15px;
        margin-top: -70px;

        transition: 600ms;
        opacity: 0;
    }

    &:hover {
        .follower-logo-note {
            transition: 400ms;
            opacity: 1;
        }

        &::after {
            transition: 400ms;
            opacity: 1;
        }
    }
}

.fixed-sidemenu {
    position: fixed;
    bottom: 50px;
    right: 50px;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    gap: 2rem;

    a {
        font-size: 2rem;
        transition: 250ms;
        color: $primaryTextColor;

        &:hover, &:focus {
            color: $titleTextColor;
        }

        img, svg {
            height: 2.3rem;
        }

        svg {
            transition: 250ms;

            g {
                transition: 250ms;
            }

            &:hover g, &:focus g {
                fill: $titleTextColor !important;
            }
        }
    }
}

#particles-js {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
}
