@import '../../common/styles/variables.scss';

.category-filter {
    display: flex;
    gap: 2rem;

    .category-filter-item {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 300;
        cursor: pointer;

        span {
            display: inline-block;
            margin-bottom: 0.5rem;
            padding: 0 1.5rem 1rem 1.5rem;
            border-bottom: 2px solid transparent;
            transition: 500ms;
            letter-spacing: 2px;
        }

        &:hover span {
            border-bottom: 2px solid $secondaryTextColor;
            transition: 400ms;
        }

        &.active {
            color: $primaryTextColor;

            span {
                border-bottom: 2px solid $primaryTextColor;
            }
        }
    }
}

.projects {
    margin-right: 20px;
    display: flex;
    flex-flow: wrap;
    gap: 0rem;

    .project {
        display: flex;
        flex: 0 0 auto;
        width: 33.33333333%;
        flex-flow: column;
        align-items: flex-start;
        border-radius: 1rem;
        transition: 250ms;
        padding: 20px;
        cursor: pointer;
        min-height: 180px;
        margin-bottom: 3rem;

        &.hidden {
            min-height: 0;
            height: 0;
            overflow: hidden;
            padding: 0;
            opacity: 0;
            transition: 250ms;
        }

        .project-image-wrapper {
            position: relative;
            padding-top: 56%;
            width: 100%;
            font-size: 0;
            display: block;
            transition: all .3s ease-in-out;
            border-radius: 1rem;
            overflow: hidden;

            .project-image {
                position: absolute;
                width: 100%;
                max-width: 120%;
                height: auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all .3s ease-in-out;
                cursor: pointer;
            }
        }

        &:hover {
            transition: 250ms;
            background-color: #070707;

            .project-image-wrapper {
                .project-image {
                    width: 120%;
                    transform: translate(-50%, -50%);
                }
            }
    
        }
        
        .project-period {
            grid-area: period;
            color: $titleTextColor;
            font-size: 1.2rem;
            font-weight: 300;
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .project-title {
            grid-area: title;
            color: $primaryTextColor;
            font-size: 2.2rem;
            font-weight: bold;
            line-height: 1.1;
            cursor: pointer;
        }

        .project-subtitle {
            grid-area: subtitle;
            font-size: 1.4rem;
            font-weight: 300;
            margin-top: 0.5rem;
        }

        .project-skills {
            grid-area: skills;
            font-size: 1.1rem;
            font-weight: 300;
            letter-spacing: 1px;

            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem 1.2rem;

            text-transform: uppercase;
            color: $secondaryTextColor;

            margin: 2rem 0;
        }

        .project-description {
            grid-area: description;
            font-size: 1.6rem;
            line-height: 1.5;
            overflow: hidden;
            padding-right: 10px;
        }
    }

    .backdrop {
        .project {
            cursor: default;

            &.expanded {
                background-color: $primaryTextColor;
                width: 50vw;
                margin: 0 auto;

                .project-title {
                    color: $baseColor;
                    font-size: 4.2rem;
                }

                .project-subtitle {
                    font-size: 2.2rem;
                    color: #6f707c;
                }

                .project-period {
                    font-size: 1.6rem;
                }

                .project-skills {
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: $baseColor;
                    margin: 1rem 0 4rem 0;
                }

                .project-description {
                    color: #6f707c;
                }

                
                .project-url {
                    font-size: 1.4rem;
                    font-weight: 400;
                    margin-top: 2.5rem;
                    background-color: $titleTextColor;
                    color: $primaryTextColor;
                    font-size: 1.2rem;
                    padding: 1.5rem 4rem;
                    border-radius: 4rem;
                    transition: 300ms;

                    &:hover, &:focus {
                        background-color: $baseColor;
                    }
                }
            }
        }
    }
}