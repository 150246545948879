@import "../../common//styles/variables.scss";

.modal {
    position: relative;
    padding-bottom: 5rem;

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 2rem;
        z-index: 100;
        transition: 250ms;
        font-size: 3.5rem;
        cursor: pointer;
        color: $baseColor;
        background-color: transparent;
        border: none;

        &:hover {
           opacity: .7;
        }
    }
}
