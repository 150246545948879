@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./variables.scss";

:root {
    --bs-font-sans-serif: $bodyFont;
}

.mx-1 { margin-left: 1rem; margin-right: 1rem; }
.mx-2 { margin-left: 2rem; margin-right: 2rem; }
.mx-3 { margin-left: 3rem; margin-right: 3rem; }
.mx-4 { margin-left: 4rem; margin-right: 4rem; }
.mx-5 { margin-left: 5rem; margin-right: 5rem; }
.mx-6 { margin-left: 5rem; margin-right: 6rem; }

.my-1 { margin-top: 1rem; margin-bottom: 1rem; }
.my-2 { margin-top: 2rem; margin-bottom: 2rem; }
.my-3 { margin-top: 3rem; margin-bottom: 3rem; }
.my-4 { margin-top: 4rem; margin-bottom: 4rem; }
.my-5 { margin-top: 5rem; margin-bottom: 5rem; }
.my-6 { margin-top: 5rem; margin-bottom: 6rem; }

.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }
.mt-4 { margin-top: 4rem; }
.mt-5 { margin-top: 5rem; }
.mt-6 { margin-top: 6rem; }

.mr-1 { margin-right: 1rem; }
.mr-2 { margin-right: 2rem; }
.mr-3 { margin-right: 3rem; }
.mr-4 { margin-right: 4rem; }
.mr-5 { margin-right: 5rem; }
.mr-6 { margin-right: 6rem; }

.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }
.mb-4 { margin-bottom: 4rem; }
.mb-5 { margin-bottom: 5rem; }
.mb-6 { margin-bottom: 6rem; }

.ml-1 { margin-left: 1rem; }
.ml-2 { margin-left: 2rem; }
.ml-3 { margin-left: 3rem; }
.ml-4 { margin-left: 4rem; }
.ml-5 { margin-left: 5rem; }
.ml-6 { margin-left: 6rem; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }

.small  { font-size: 0.75rem; }
.normal { font-size: 1.00rem; }
.medium { font-size: 1.50rem; }
.lerge  { font-size: 2.00rem; }

html {
    font-size: 62.56%;
    font-family: $bodyFont;
    background-image: $siteBackground;
    min-height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0 auto;
    margin-left: 320px;
    margin-right: 315px;
    color: $secondaryTextColor;
}

a {
    color: $primaryTextColor;
    text-decoration: none;
    outline: none;
}

.d-none { display: none; }
.d-block { display: block; }
.d-flex { display: flex; }

button.button,
a.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $primaryTextColor;
    text-decoration: none;
    font-size: 1.2rem;
    color: #000000;
    padding: 1.5rem 4rem;
    border-radius: 4rem;
    transition: 300ms;
    position: relative;
    outline: none;

    &:hover, &:focus {
        background-color: $titleTextColor;
        color: $primaryTextColor;

        &::before,
        &::after {
            border-color: rgba(255, 255, 255, 0.075);
        }
    }

    $speed: 100ms;

    @keyframes pulsing {
        0% {
            width: 0px;
            opacity: 1;
        }
        
        50% {
            opacity: 1;
        }
        
        100% {
            width: 50%;
            opacity: 0;
        }
    }

    &::before,
    &::after {
        z-index: 1;
        content: '';
        display: block;
        transition: $speed;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        aspect-ratio: 1;
    }

    &::before {
        border: 3px solid rgba(60, 60, 60, 0.15);
        animation: pulsing 2s infinite;
    }

    &::after {
        border: 1.5px solid rgba(60, 60, 60, 0.15);
        animation: pulsing 2s infinite 150ms;
    }
}
