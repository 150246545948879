@import '../../common/styles/variables.scss';

.page {
    display: flex;
    padding-top: 150px;
    min-height: calc(100vh - 100px);
    align-items: center;
    position: relative;
    // padding-left: 75px;

    .page-content {
        width: 100%;
    }

    .page-name {
        text-transform: uppercase;
        color: $titleTextColor;
        font-weight: 300;
        font-size: 1.8rem;
        margin: 0.5rem 0;
        letter-spacing: 2px;

        &[data-title]::after {
            content: attr(data-title);
            color: $secondaryTextColor;
            position: absolute;
            font-size: 1.2rem;
            transform: rotate(-90deg);
            background-color: #000000;
            padding: 20px 20px;
            left: -329px;
            top: 75vh;
            width: 120px;
            text-align: center;
            letter-spacing: 2px;
        }
    }

    .page-title {
        font-weight: 700;
        color: $primaryTextColor;
        font-size: 5.5rem;
        line-height: 1.1;
    }

    .page-subtitle {
        font-weight: 700;
        color: $secondaryTextColor;
        font-size: 5rem;
        line-height: 1.1;
    }

    .page-text {
        font-weight: 400;
        font-size: 1.8rem;

        &.indented {
            margin-left: 250px;
            margin-right: 250px;
        }

        p {
            margin-bottom: 3rem;
            line-height: 1.5;
        }
    }
}
