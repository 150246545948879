@import '../../common/styles/variables.scss';

.service-block-holder {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    justify-items: stretch;
    align-items: stretch;

    .service-block {
        display: flex;
        flex-direction: column;
        max-width: 330px;
        gap: 1.5rem;
        padding: 2rem;
        transition: 500ms;

        &:hover {
            background: #53535c20;
        }

        .service-block-image {
            width: 45px;
            aspect-ratio: 1;
        }

        .service-block-title {
            font-size: 1.6rem;
            font-weight: bold;
            color: $primaryTextColor;
        }

        .service-block-text {
            font-size: 1.3rem;
        }
    }
}
