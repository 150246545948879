@import '../../common/styles/variables.scss';

.recommendation-slider-holder {
    position: relative;

    .recommendation-slider-dots {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 0.5rem;

        .recommendation-slider-dot {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $primaryTextColor;
            border: 0.1rem solid #000;
            transition: 1200ms;
            cursor: pointer;
            opacity: 0.2;

            &.active {
                transition: 500ms;
                border: 0.2rem solid $primaryTextColor;
                opacity: 1.0;
            }
        }
    }

    .recommendation-slider-arrow {
        position: absolute;
        top: 50%;
        font-size: 3rem;
        color: $primaryTextColor;
        padding: 3rem;
        cursor: pointer;
        transition: 500ms;
        transform: translateY(-50px);

        &.left {
            left: -75px;
        }

        &.right {
            right: -75px;
        }
    }

    .recommendation-slider-wrapper {
        width: 100%;
        overflow: scroll;

        scroll-snap-type: x mandatory;
        scroll-snap-points-x: repeat(100%);

        .recommendation-slider {
            padding: 0;

            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            transition: transform 0.7s ease-in-out;

            .recommendation-slider-item {
                scroll-snap-align: start;
                padding: 0 30px;

                width: 100%;
            	flex-shrink: 0;

                margin-right: 250px;

                .recommendation-slider-item-text {
                    padding: 3rem;
                    font-size: 1.6rem;
                    margin-bottom: 0;
                    text-align: center;
                }
    
                .recommendation-slider-item-person {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    font-size: 1.8rem;
    
                    .recommendation-slider-item-person-photo-holder {
                        grid-area: photo;
    
                        img {
                            width: 70px;
                            aspect-ratio: 1;
                            border-radius: 50%;
                        }
                    }
    
                    .recommendation-slider-item-name {
                        grid-area: fullname;
                        line-height: 1.2;
                        margin-top: 0.8rem;
                        font-weight: 700;
                    }
    
                    .recommendation-slider-item-title {
                        grid-area: title;
                        margin-top: 0.3rem;
                        font-size: 1.2rem;
                    }

                    .recommendation-slider-item-company {
                        grid-area: company;
                        font-size: 1.2rem;
                        margin-top: 0.3rem;
                        color: $primaryTextColor;
                    }
    
                    .recommendation-slider-item-date {
                        grid-area: date;
                        margin-top: 0.3rem;
                        font-size: 0.9rem;
                        display: none;
                    }
                }
            }
        }
    }
}
