// Global
$baseColor: #000000;
$lineColor: #121212;

$siteBackgroundEnd: 100%;

$siteBackground: linear-gradient(
    90deg,

    $baseColor 0%,
    $baseColor $siteBackgroundEnd
);
$bodyFont: 'Poppins', sans-serif;

// Text
$titleTextColor: #bd0b0c;
$primaryTextColor: #ffffff;
$secondaryTextColor: #a6a7b8;
