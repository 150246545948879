.backdrop {
    opacity: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 10000;
    padding: 5rem;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    display: none;

    &.visible {
        display: block;
    }
}
