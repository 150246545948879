@import '../../common/styles/variables.scss';

.counters {
    display: flex;
    gap: 2rem;
    width: 100%;
    padding-top: 5rem;
    margin-bottom: 0;

    .counter {
        flex-grow: 1;
        flex-basis: 0;

        .counter-value {
            font-size: 5.5rem;
            font-weight: bold;
            color: $primaryTextColor;
        }

        .counter-name {
            font-size: 1.8rem;
        }
    }
}
